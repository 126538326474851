import create from 'zustand';
import { useThemeStore } from './theme';

const initialStoreState = {
    open: false,
    showOnlyModalContentViewer: true,
    content: {},
    showPdfTron: false,
    activeKey: 'tab1',
    items: [],
    newTabIndex: 2,
    selectedMenuItem: 'Content',
    isContentMenuOpen: false,
    pdfTronVideoInstance: null,
    inlinePDFTronVideoInstance: null,
    tableViewerParams: {
        paginate: {
            page: 1,
            limit: 50,
        },
    },
};

const useContentViewerStore = create((set, get) => ({
    ...initialStoreState,

    pdfTronInstance: null,
    inlinePDFTronInstance: null,

    viewContent: (content, open = true) => {
        if (open) {
            set({ ...initialStoreState, open: open });
        }
        set({ content: content });
    },

    closeInlineContentViewer: () => {
        set({
            ...initialStoreState,
        });
        get().inlinePDFTronInstance?.iframeWindow.removeEventListener(
            'themeChanged',
            useThemeStore.getState().toggleTheme
        );
    },

    closeContentViewer: () => {
        if (get().showOnlyModalContentViewer) {
            get().closeInlineContentViewer();
        } else {
            set({
                open: false,
            });
        }
    },

    setContent: content => {
        set({ content });
    },

    setOpen: open => set({ open: open }),

    setShowOnlyModalContentViewer: showOnlyModalContentViewer =>
        set({ showOnlyModalContentViewer }),

    setPDFTronInstance: instance => set({ pdfTronInstance: instance }),

    setInlinePDFTronInstance: instance =>
        set({ inlinePDFTronInstance: instance }),

    setShowPdfTron: isHidden => set({ showPdfTron: isHidden }),

    setActiveKey: activeKey => {
        set({ activeKey: activeKey });
    },

    setItems: items => {
        set({ items: items });
    },

    setSelectedMenuItem: selectedMenuItem => {
        set({ selectedMenuItem: selectedMenuItem });
    },

    setTableViewerParams: tableViewerParams => {
        set({ tableViewerParams });
    },

    toggleContentMenu: () => {
        set({ isContentMenuOpen: !get().isContentMenuOpen });
    },
}));

export default useContentViewerStore;
