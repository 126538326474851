import React, { useState } from 'react';
import {
    AppstoreOutlined,
    ControlOutlined,
    MessageOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, Row, Col, Space, theme } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserStore } from '../store/user';
const { useToken } = theme;

export default function AppStore() {
    const userRole = useUserStore(state => state.userRole);
    const { token } = useToken();
    const [open, setOpen] = useState(false);
    const currentButtonStyle = {
        color: token.colorPrimary,
        borderColor: token.colorPrimary,
    };
    const navigate = useNavigate();
    const location = useLocation();
    const inAdminPanel = !!location?.pathname?.match(/^\/admin/);
    const items = [
        {
            key: '1',
            label: (
                <Button
                    style={{
                        height: '4em',
                        ...(inAdminPanel ? currentButtonStyle : {}),
                    }}
                    onClick={() => {
                        navigate('/admin');
                        setOpen(false);
                    }}
                    disabled={userRole !== 'admin'}
                    size="large"
                >
                    <Row justify="center" align="middle">
                        <Col>
                            <ControlOutlined style={{ fontSize: '2em' }} />
                        </Col>
                        <Col span={24}>Admin Panel</Col>
                    </Row>
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button
                    style={{
                        height: '4em',
                        ...(!inAdminPanel ? currentButtonStyle : {}),
                    }}
                    onClick={() => {
                        navigate('/');
                        setOpen(false);
                    }}
                    size="large"
                >
                    <Row justify="center" align="middle">
                        <Col>
                            <MessageOutlined style={{ fontSize: '2em' }} />
                        </Col>
                        <Col span={24}>Virtual Assist</Col>
                    </Row>
                </Button>
            ),
        },
    ];
    return (
        <Dropdown
            open={open}
            onOpenChange={setOpen}
            menu={{
                items,
            }}
            dropdownRender={menu => (
                <Card size="small" title="Apps" hoverable>
                    <Space size={[8, 8]} wrap>
                        {[
                            menu.props.items.map(item => (
                                <React.Fragment key={item.key}>
                                    {item.label}
                                </React.Fragment>
                            )),
                        ]}
                    </Space>
                </Card>
            )}
        >
            <Button
                shape="circle"
                size="large"
                icon={<AppstoreOutlined />}
            ></Button>
        </Dropdown>
    );
}
