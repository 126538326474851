import React, { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import './assets/css/App.css';
import './assets/css/style.css';
import './assets/css/troubleshoot.css';
import 'reactflow/dist/style.css';
import { ConfigProvider, theme, message } from 'antd';
import { useThemeStore } from './store/theme';
import Layout from './Layout';
import Fallback from './common/Fallback';
import ErrorBoundary from './common/ErrorBoundaries';
import SomethingWentWrong from './common/SomethingWentWrong';
import { useUserStore } from './store/user';
const ContentViewer = lazy(() => import('./common/ContentViewer'));
const ChatBox = lazy(() => import('./components/ChatBox'));
import ChartModal from 'common/Chart/ChartModal';
import Routes from 'Routes';
message.config({
    duration: 2,
    maxCount: 3,
});

function App() {
    const currentTheme = useThemeStore(state => state.theme);
    const userId = useUserStore(state => state.userId);
    const location = useLocation();

    return (
        <ConfigProvider
            theme={{
                algorithm:
                    currentTheme === 'dark'
                        ? theme.darkAlgorithm
                        : theme.defaultAlgorithm,
            }}
        >
            <Layout>
                <ChartModal />
                <ErrorBoundary
                    fallback={<SomethingWentWrong />}
                    key={location.pathname}
                >
                    <Suspense fallback={<Fallback />}>
                        <Routes />
                        {userId && <ContentViewer />}
                    </Suspense>
                </ErrorBoundary>
                <ErrorBoundary fallback={null} key="chatbox-errorboundary">
                    <Suspense fallback={null}>
                        {userId && ChatBox && <ChatBox />}
                    </Suspense>
                </ErrorBoundary>
            </Layout>
        </ConfigProvider>
    );
}

export default App;
