import create from 'zustand';
import axios from 'services/api';

export const useNotificationsStore = create(() => ({
    getNotifications: async (
        unreadOnly = false,
        page = 1,
        limit = 10,
        sort = 'desc_created'
    ) =>
        await axios
            .get('/notifications', {
                params: {
                    unread_only: unreadOnly,
                    page,
                    limit,
                    sort,
                },
            })
            .catch(error => console.error(error)),

    markNotificationAsRead: async notificationId =>
        await axios.put(`/notifications/${notificationId}`, {
            is_read: true,
        }),
}));
