import React from 'react';
import { Modal } from 'antd';
import Chart from '.';
import { useChartStore } from 'store/chart';

const ChartModal = () => {
    const {
        chartData,
        chartLayout,
        chartConfig,
        chartStyle,
        isChartModalOpen,
        setIsChartModalOpen,
    } = useChartStore(state => ({
        chartData: state.chartData,
        chartLayout: state.chartLayout,
        chartConfig: state.chartConfig,
        chartStyle: state.chartStyle,
        isChartModalOpen: state.isChartModalOpen,
        setIsChartModalOpen: state.setIsChartModalOpen,
    }));

    const handleCancel = () => {
        setIsChartModalOpen(false);
    };
    const handleOk = () => {};
    return (
        <>
            <Modal
                title=""
                open={isChartModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{
                    top: 20,
                    height: '100%',
                }}
                width={'100%'}
                footer={null}
            >
                <div className="margin_top">
                    <Chart
                        data={chartData}
                        layout={chartLayout}
                        config={chartConfig}
                        style={chartStyle}
                    />
                </div>
            </Modal>
        </>
    );
};

export default ChartModal;
