import React, { useEffect } from 'react';
import { useUserStore } from 'store/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRouteStore } from 'store/routes';

export default function withAuth(Component) {
    function AuthorizedComponent(props) {
        const routeKey = useRouteStore(state => state.routeKey);
        const { username, expiry, logout, userRole, releaseVersion } =
            useUserStore(state => ({
                username: state.username,
                expiry: state.expiry,
                logout: state.logout,
                userRole: state.userRole,
                releaseVersion: state.releaseVersion,
            }));
        const navigate = useNavigate();
        const location = useLocation();

        useEffect(() => {
            if (new Date(expiry) - new Date() <= 0) {
                logout();
                navigate('/login');
            } else if (!username && location.pathname !== '/login') {
                navigate('/login');
            } else if (
                userRole !== 'admin' &&
                location.pathname.match(/\/admin*/)
            ) {
                navigate('/');
            } else if (
                username &&
                location.pathname === '/login' &&
                releaseVersion
            ) {
                navigate(location.key !== 'default' ? -1 : '/', {
                    replace: true,
                });
            }
        }, [username, releaseVersion]);
        return <Component {...props} key={routeKey} />;
    }
    return <AuthorizedComponent />;
}
