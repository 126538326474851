import create from 'zustand';
import { message } from 'antd';
import { useUserStore } from './user';
import axios from 'services/api';

export const useViewedHistoryStore = create((set, get) => ({
    viewHistory: {},
    historyId: '',
    getViewHistory: async () =>
        await axios(`/virtual-assist/viewed-content-items/?sort=desc_updated`)
            .then(res => set({ viewHistory: res.data }))
            .catch(err => {
                if (!useUserStore.getState().userId) return;
                message.error("Couldn't fetch viewed history");
                console.error(err);
            }),

    createViewHistory: async (contentId, docId, contentType) =>
        await axios
            .post(`/virtual-assist/viewed-content-items/`, {
                content_id: contentId,
                doc_id: docId,
                content_type: contentType,
            })
            .then(res => set({ historyId: res.data?.data?.history_id }))
            .then(() => get().getViewHistory()),

    updateViewHistory: async () =>
        await axios
            .put(`/virtual-assist/viewed-content-items/${get().historyId}`, {})
            .then(() => get().getViewHistory()),

    getContentHistory: async types =>
        await axios(
            `/virtual-assist/viewed-content-items/?sort=desc_updated&content_type=${
                types?.join(',') || ''
            }`
        ),
}));
