import { Layout, Col, Row, Space } from 'antd';
import React from 'react';
import GlobalSearch from './GlobalSearch';
import SideMenu from './SideMenu';
import UserIcon from './UserIcon';
import AppStore from './AppStore';
import { useUserStore } from '../store/user';
import lavorroLogo from 'assets/img/Lavorro_Logo.png';
import NotificationsIcon from './NotificationsIcon';

const { Header, Content } = Layout;
export default function App({ children, getMenuItem }) {
    const { projectName, releaseVersion, releaseDate, release } = useUserStore(
        state => ({
            projectName: state.projectName,
            releaseVersion: state.releaseVersion,
            releaseDate: state.releaseDate,
            release: state.release,
        })
    );
    return (
        <Layout>
            <Header
                className="header"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Row
                    justify="center"
                    align="middle"
                    style={{
                        maxWidth: '150px',
                        minWidth: 'min(150px, 25%)',
                    }}
                >
                    <img src={lavorroLogo} alt="logo" />
                    <Col
                        style={{
                            color: 'white',
                            lineHeight: 'normal',
                        }}
                    >
                        <Space
                            style={{
                                fontSize: '.75em',
                                gap: '.75em',
                            }}
                        >
                            {[projectName, releaseVersion, releaseDate]
                                .filter(value => !!value)
                                .map(value => (
                                    <>{value}</>
                                ))}
                        </Space>
                    </Col>
                </Row>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1em',
                    }}
                >
                    {/* <GlobalSearch /> */}
                    <AppStore />
                    <UserIcon />
                    {!!release?.NOTIFICATIONS && <NotificationsIcon />}
                </Row>
            </Header>
            <Layout style={{ height: '100vh', overflow: 'hidden' }}>
                <SideMenu getMenuItem={getMenuItem} />
                <Content
                    className="site-layout-background"
                    style={{
                        padding: '24px 24px 24px 0px',
                        margin: 0,
                        marginBottom: '5em',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}
