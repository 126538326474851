import create from 'zustand';
import axios from 'services/api';

export const useDashboardStore = create((set, get) => ({
    getDashboards: async (dashboardType = '', page = 1, limit = 10) =>
        await axios.get('/dashboards/', {
            params: {
                page,
                limit,
                dashboard_type: dashboardType,
            },
        }),
    updateDashboard: async (dashboardId, dashboardData) =>
        await axios.put(`/dashboards/${dashboardId}`, dashboardData),
    addNewDashboard: async dashboardData =>
        await axios.post(`/dashboards/publish`, dashboardData),
    deleteDashboard: async dashboardId =>
        await axios.delete(`dashboards/${dashboardId}`),
}));
