import { Button, List, Card, Typography, Row } from 'antd';
import React, { useEffect } from 'react';
import { useBookmarkStore } from '../../store/bookmark';
import { useNavigate } from 'react-router-dom';
import { StarOutlined } from '@ant-design/icons';
import useContentViewerStore from '../../store/contentViewer';
import { useUserStore } from '../../store/user';
import HtmlParser from 'react-html-parser';
import { featureRoutesList } from 'Routes';

export default function BookmarksWidget() {
    const { bookmarks, getBookmarks, page, limit } = useBookmarkStore();
    const viewContent = useContentViewerStore(state => state.viewContent);
    const username = useUserStore(state => state.username);
    const { Title, Paragraph } = Typography;
    const navigate = useNavigate();

    useEffect(() => {
        if (!username) return;
        if (!bookmarks?.data) getBookmarks({ page, limit });
    }, []);

    const viewMore = (
        <Button
            style={{ marginTop: '2em' }}
            onClick={() => navigate(featureRoutesList['SEARCH'].concat('/bookmarks'))}
        >
            View More
        </Button>
    );

    return (
        <Card style={{ width: '100%', height: '100%' }} hoverable>
            <Title
                level={3}
                style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
            >
                <StarOutlined />
                Recently Bookmarked
            </Title>
            <List
                size="small"
                itemLayout="horizontal"
                loadMore={viewMore}
                dataSource={bookmarks?.data?.reply?.slice(0, 5)}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                type="primary"
                                onClick={() => viewContent(item)}
                            >
                                View Content
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Title level={5} ellipsis={true}>
                                    {HtmlParser(
                                        item?.title ||
                                            item?.heading ||
                                            item?.term?.toUpperCase() ||
                                            item?.question
                                    )}
                                </Title>
                            }
                            description={
                                <Paragraph ellipsis={{ row: 1 }}>
                                    {HtmlParser(
                                        item?.description ||
                                            item?.definition ||
                                            item?.answer
                                    )}
                                </Paragraph>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
}
