import axios from 'axios';
import Config from 'config/Config';

const instance = axios.create({
    baseURL: Config.API_URL,
    headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(config => {
    if (config.url === '/grafana/login') {
        config.baseURL = Config.GRAFANA;
        delete config.headers.Authorization;
        return config;
    }
    if (config.headers.Authorization !== `Token null`) return config;
    return config;
});

export default instance;
