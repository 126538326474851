import { message } from 'antd';
import create from 'zustand';
import { useUserStore } from './user';
import axios from 'services/api';
import useContentViewerStore from './contentViewer';

export const useBookmarkStore = create((set, get) => ({
    bookmarks: {},
    query: '',
    page: 1,
    limit: 10,
    filters: [],
    totalItems: 0,
    getBookmarks: async (
        { page = 1, limit = 10, filters = [] },
        closeInlineContentViewer = true
    ) => {
        if (closeInlineContentViewer) {
            useContentViewerStore.getState().closeInlineContentViewer();
        }
        set({ page, limit, filters });
        await axios
            .get(
                `virtual-assist/bookmarks/?page=${page}&limit=${limit}${filters
                    ?.map(filter => `&content_type=${filter}`)
                    .join('')}&sort=desc_updated`
            )
            .then(res => {
                if (!res.data?.data?.reply?.length && get().page - 1)
                    return get().getBookmarks({
                        page: get().page - 1,
                        limit: get().limit,
                    });
                set({
                    bookmarks: res.data,
                });
            })
            .catch(err => {
                if (!useUserStore.getState().userId) return;
                message.error("Couldn't fetch bookmarks");
                console.error(err);
            });
    },

    setBookmarks: bookmarks => {
        set({ bookmarks });
    },

    updateBookmarks: async () => {
        await get().getBookmarks(
            { page: get().page, limit: get().limit, filters: get().filters },
            false
        );
    },
    deleteBookmark: async bookmark_id =>
        await axios
            .delete(`/virtual-assist/bookmarks/${bookmark_id}/`)
            .then(async () => {
                await get().updateBookmarks();
                message.success('Bookmark removed!');
            }),
    addBookmark: async (content_id, doc_id, content_type) => {
        let bookmarkId = '';
        await axios
            .post(`/virtual-assist/bookmarks/`, {
                content_id,
                doc_id,
                content_type,
            })
            .then(async res => {
                bookmarkId = res?.data?.data?.bookmark_id;
                await get().updateBookmarks();
                message.success('Successfully bookmarked!');
            });
        return bookmarkId;
    },
}));
