import { BellTwoTone } from '@ant-design/icons';
import { Badge, Button, Dropdown, Typography, notification } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useNotificationsStore } from 'store/notifications';
import './style.css';
import ViewAllNotifications from './ViewAllNotifications';

export default function NotificationsIcon() {
    const [api, contextHolder] = notification.useNotification();
    const { getNotifications, markNotificationAsRead } = useNotificationsStore(
        state => ({
            getNotifications: state.getNotifications,
            markNotificationAsRead: state.markNotificationAsRead,
        })
    );
    const [notifications, setNotifications] = useState([]);

    const [items, setItems] = useReducer((_, newState) => {
        setNotifications(newState?.data?.data);
        return (
            newState?.data?.data?.map(notification => ({
                key: notification.notification_id,
                label: (
                    <Button type="ghost" block>
                        <Typography.Text
                            style={{ width: '20em' }}
                            ellipsis={true}
                        >
                            {notification?.message || 'New Notification'}
                        </Typography.Text>
                    </Button>
                ),
            })) || []
        );
    }, []);

    const handleNotificationClick = async e => {
        if (e.key === 'view_all_notifications') return;
        await markNotificationAsRead(e.key);
        await getNotifications(true);
    };

    const notificationAlerts = notifications => {
        if (!!notifications?.length) {
            const timeNow = new Date(new Date().toUTCString());
            notifications?.forEach(notification => {
                const notificationCreatedTime = new Date(notification?.created);
                const timeDiff = Math.abs(timeNow - notificationCreatedTime);
                if (timeDiff < 6000) {
                    api['info']({
                        message: notification?.title || 'New Notification',
                        description: notification?.message || '',
                        placement: 'bottomRight',
                    });
                }
            });
        }
    };

    useEffect(() => {
        (async () => await getNotifications(true).then(setItems))();
        const pollingHistory = setInterval(async () => {
            await getNotifications(true).then(response => {
                setItems(response);
                notificationAlerts(response?.data?.data);
            });
        }, 5000);
        return () => clearInterval(pollingHistory);
    }, []);

    return (
        <>
            <Dropdown
                menu={{
                    items: [
                        ...items,
                        {
                            key: 'view_all_notifications',
                            label: <ViewAllNotifications />,
                        },
                    ],
                    onClick: handleNotificationClick,
                }}
            >
                <Badge
                    count={notifications?.length}
                    className="notifications_badge"
                    overflowCount={9}
                >
                    <Button shape="circle" size="large">
                        <BellTwoTone />
                    </Button>
                </Badge>
            </Dropdown>
            {contextHolder}
        </>
    );
}
