import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, message, Card } from 'antd';
import { useUserStore } from 'store/user';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import lavorroLogo from 'assets/img/Lavorro_Logo.png';

export default function Login() {
    const { login, getEnvVars, grafanaLogin, handleLoginResponse } =
        useUserStore(state => ({
            username: state.username,
            login: state.login,
            getEnvVars: state.getEnvVars,
            grafanaLogin: state.grafanaLogin,
            handleLoginResponse: state.handleLoginResponse,
        }));
    const [loggingIn, setLoggingIn] = useState(false);

    const onFinish = async ({ username, password }) => {
        setLoggingIn(true);
        await login(username, password)
            .then(response => {
                message.success({
                    content: response?.data?.data?.value,
                    key: 'login-success',
                });
                handleLoginResponse(response);
            })
            .catch(err => {
                console.error(err);
                err?.response?.data?.error_message &&
                    message.error({
                        content: err?.response?.data?.error_message,
                        key: 'login-fail',
                    });
            });
        await getEnvVars().catch(e => console.error(e));
        await grafanaLogin();
        setLoggingIn(false);
    };

    return (
        <Row
            justify="center"
            align="middle"
            style={{
                background: ' rgb(0,134,255)',
                background:
                    'radial-gradient(circle, rgba(0,134,255,1) 0%, rgba(7,46,92,1) 100%)',
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                top: 0,
                left: 0,
                overflow: 'hidden',
                zIndex: 1003,
            }}
        >
            <Col xs={18} sm={18} md={8} lg={8} xl={6}>
                <Card>
                    <Row justify="center" align="middle" gutter={[16, 16]}>
                        <Col xs={18} sm={18} md={12} lg={12} xl={12}>
                            <img src={lavorroLogo} alt="logo" />
                        </Col>
                        <Col span={24}>
                            <Form
                                size="large"
                                name="login"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    colon={false}
                                    name="username"
                                    style={{ color: 'white' }}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your username',
                                        },
                                        {
                                            message:
                                                'Username must be at least 2 characters',
                                            min: 2,
                                        },
                                        {
                                            message:
                                                'Username must not exceed 64 characters',
                                            max: 64,
                                        },
                                        {
                                            message:
                                                'Please enter a valid username',
                                            pattern:
                                                /^[A-Za-z0-9_\-.@!#$%^&]{2,64}$/,
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<UserOutlined />}
                                        placeholder="Username"
                                    />
                                </Form.Item>

                                <Form.Item
                                    colon={false}
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your password',
                                        },
                                        {
                                            message:
                                                'Password must be at least 8 characters',
                                            min: 8,
                                        },
                                        {
                                            message:
                                                'Password must not exceed 64  characters',
                                            max: 64,
                                        },
                                        {
                                            message:
                                                'Please enter a valid password',
                                            pattern:
                                                /^[A-Za-z0-9_\-.@!#$%^&]{8,64}$/,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        block={true}
                                        loading={loggingIn}
                                    >
                                        <b>Login</b>
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
