import React from 'react';
import { Row, Spin } from 'antd';

export default function Fallback() {
    return (
        <Row
            style={{ width: '100%', height: '100%' }}
            justify="center"
            align="middle"
        >
            <Spin size="large" spinning={true} />
        </Row>
    );
}
