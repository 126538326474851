import create from 'zustand';
import axios from 'services/api';
import { message } from 'antd';

const initialStoreState = {
    isNoteModalOpen: false,
    isNotesViewer: false,
    isEdited: false,
    showConfirmModal: false,
    notesItem: [],
    notesInfo: [],
    content: '',
    htmlContent: '',
    title: '',
    _id: '',
    editor: false,
    page_no: 1,
    totalItems: 0,
    createNewNotes: false,
    createdNoteInfo: {},
};

export const useNotesStore = create((set, get) => ({
    ...initialStoreState,
    resetStore: () => {
        set({
            ...initialStoreState,
        });
    },
    setIsNoteModalOpen: isNoteModalOpen => {
        set({ isNoteModalOpen });
    },
    setCreateNewNotes: value => {
        set({ createNewNotes: value });
    },
    toggleEditor: () => {
        set(state => ({ editor: !state.editor }));
    },
    setNoteEditor: value => {
        set({ editor: value });
    },
    setViewerModal: value => {
        set({ isNotesViewer: value });
    },
    startEditing: () => {
        set({ isEdited: true });
    },
    notEditing: () => {
        set({ isEdited: false });
    },
    toggleConfirmModal: () => {
        set(state => ({ showConfirmModal: !state.showConfirmModal }));
    },
    setTitle: value => {
        set({ title: value });
    },
    setNoteId: value => {
        set({ _id: value });
    },
    setContent: value => {
        set({ content: value });
    },
    setNotesItem: value => {
        set({ notesItem: value });
    },
    notesList: async () =>
        await axios
            .get(`/notes/`)
            .then(res => {
                set({
                    notesItem: res.data.data ? res.data.data : [],
                    totalItems: res.data.meta.total_records,
                    page_no: 1,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    notesSearch: async val =>
        await axios
            .get(`/notes?search_query=${val}`)
            .then(res => {
                set({
                    page_no: 1,
                    notesItem: res.data.data ? res.data.data : [],
                    totalItems: res.data.meta.total_records,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    notesListWithPage: async () =>
        await axios
            .get(`/notes?page=${get().page_no + 1}`)
            .then(res => {
                set({
                    notesItem: get().notesItem.concat(res.data.data),
                    page_no: get().page_no + 1,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    getNotesHtml: async url =>
        await axios
            .get(url)
            .then(res => {
                set({
                    htmlContent: res.data,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    getNotesContent: async id =>
        await axios
            .get(`/notes/${id}`)
            .then(res => {
                set({
                    htmlContent: res?.data?.data?.content,
                    notesInfo: res?.data?.data,
                });
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    setNotesHtml: value => {
        set({ htmlContent: value });
    },
    createNote: async data =>
        await axios
            .post(`/notes/`, data)
            .then(res => {
                message.success('Notes created');

                set({ title: '', createdNoteInfo: res.data.data });
                get().notesList();
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    saveNote: async categories =>
        await axios
            .put(`/notes/${get()._id}`, {
                content: localStorage.getItem('note_content'),
                title: get().title,
                categories: categories,
            })
            .then(res => {
                message.success('Notes saved');
                set({
                    title: '',
                    notesHtml: localStorage.getItem('note_content'),
                });

                setTimeout(() => {
                    get().notesList();
                }, 1000);
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
    deleteNote: async () =>
        await axios
            .delete(`/notes/${get()._id}/`)
            .then(res => {
                message.success('Notes deleted');
                get().notesList();
            })
            .catch(err => console.error(err?.response?.data?.error_message)),
}));
