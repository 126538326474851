import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

export default function IframeNavigation({
    iframeRef,
    backBtnStyle = {},
    forwardBtnStyle = {},
}) {
    const goBack = () => {
        if (
            iframeRef?.current?.contentWindow?.navigation?.canGoBack ||
            iframeRef?.current?.contentWindow?.navigation?.canGoBack ===
                undefined
        ) {
            iframeRef?.current?.contentWindow?.history.back();
        }
    };

    const goForward = () => {
        if (
            iframeRef?.current?.contentWindow?.navigation?.canGoForward ||
            iframeRef?.current?.contentWindow?.navigation?.canGoForward ===
                undefined
        ) {
            iframeRef?.current?.contentWindow?.history.forward();
        }
    };

    return (
        <span>
            <Tooltip title="Go back in dashboard">
                <Button
                    onClick={goBack}
                    style={{ ...backBtnStyle, marginRight: '8px' }}
                    icon={<ArrowLeftOutlined />}
                    type="text"
                />
            </Tooltip>
            <Tooltip title="Go forward in dashboard">
                <Button
                    onClick={goForward}
                    style={{ ...forwardBtnStyle }}
                    icon={<ArrowRightOutlined />}
                    type="text"
                />
            </Tooltip>
        </span>
    );
}
