import React from 'react';
import { Avatar, Dropdown, Switch, Button } from 'antd';
import { useThemeStore } from '../store/theme';
import { useUserStore } from '../store/user';
import { theme } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

export default function UserIcon() {
    const { toggleTheme } = useThemeStore(state => ({
        toggleTheme: state.toggleTheme,
    }));
    const { useToken } = theme;
    const { token } = useToken();
    const name = useUserStore(state => state.name);
    const resetUserStore = useUserStore(state => state.resetUserStore);
    const navigate = useNavigate();
    function logOut() {
        resetUserStore();
        localStorage.clear();
        navigate('/login');
    }

    const items = [
        {
            key: 'logout',
            label: (
                <Button
                    icon={<LogoutOutlined />}
                    type="text"
                    size="large"
                    block={true}
                >
                    Logout
                </Button>
            ),
            onClick: logOut,
        },
        {
            key: 'toggleTheme',
            label: (
                <Button type="text" size="large" block={true}>
                    <Switch
                        checkedChildren="Light"
                        unCheckedChildren="Dark"
                        onChange={() => {
                            toggleTheme();
                        }}
                    />
                </Button>
            ),
        },
    ];
    return (
        <Dropdown menu={{ items }} placement="bottom">
            <Avatar
                size="large"
                style={{
                    background: token.colorPrimary,
                    verticalAlign: 'middle',
                }}
            >
                {name
                    ?.split(' ')
                    ?.map(value => value?.at(0)?.toUpperCase())
                    .join('')}
            </Avatar>
        </Dropdown>
    );
}
