import create from 'zustand';

import axios from 'services/api';
import { message } from 'antd';

const initialStoreState = {
    chartData: [],
    chartLayout: {},
    chartStyle: {},
    chartConfig: {},
    isChartModalOpen: false,
};

export const useChartStore = create((set, get) => ({
    ...initialStoreState,
    resetStore: () => {
        set({
            ...initialStoreState,
        });
    },
    setChartData: chartData => {
        set({
            chartData,
        });
    },
    setChartLayout: chartLayout => {
        set({
            chartLayout,
        });
    },
    setChartStyle: chartStyle => {
        set({
            chartStyle,
        });
    },
    setChartConfig: chartConfig => {
        set({
            chartConfig,
        });
    },
    setIsChartModalOpen: isChartModalOpen => {
        set({
            isChartModalOpen,
        });
    },
}));
