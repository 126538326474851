import { Button, List, Card, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { BarChartOutlined } from '@ant-design/icons';
import { useViewedHistoryStore } from '../../store/viewedHistory';
import { useUserStore } from '../../store/user';
import useContentViewerStore from '../../store/contentViewer';
import HtmlParser from 'react-html-parser';

export default function Widget() {
    const { getContentHistory } = useViewedHistoryStore();
    const viewContent = useContentViewerStore(state => state.viewContent);
    const { username } = useUserStore();
    const { Title, Paragraph } = Typography;
    const [dashboards, setDashboards] = useState([]);

    useEffect(() => {
        if (!username) return;
        getContentHistory(['dashboard'])
            .then(res =>
                setDashboards(res?.data?.data?.reply?.slice(0, 5) || [])
            )
            .catch(err => message.error("Couldn't get Dashboard History"));
    }, []);

    return (
        <Card style={{ width: '100%', height: '100%' }} hoverable>
            <Title
                level={3}
                style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}
            >
                <BarChartOutlined />
                Recently Viewed Dashboards
            </Title>
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={dashboards}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                type="primary"
                                onClick={() => viewContent(item)}
                            >
                                View Content
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <Title level={5} ellipsis={true}>
                                    {HtmlParser(item.title || item.heading)}
                                </Title>
                            }
                            description={
                                <Paragraph ellipsis={{ row: 1 }}>
                                    {HtmlParser(item?.description)}
                                </Paragraph>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
}
