import create from 'zustand';

export const useThemeStore = create(set => ({
    theme: localStorage.getItem('theme') || 'light',
    toggleTheme: () =>
        set(state => {
            const currentTheme = state.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', currentTheme);
            return { theme: currentTheme };
        }),
}));
