import { AutoComplete, Input } from 'antd';
import React, { useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

function AutoCompleteComponent() {
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    const options = useMemo(
        () => [
            {
                label: `Search "${value}" in PDFs`,
                value: `/search/content-search/${value}?content-type=pdf`,
            },
            {
                label: `Search "${value}" in Videos`,
                value: `/search/content-search/${value}?content-type=video`,
            },
            {
                label: `Search "${value}" in Images`,
                value: `/search/content-search/${value}?content-type=image`,
            },
            {
                label: `Search "${value}" in Dashboards`,
                value: `/search/content-search/${value}?content-type=dashboard`,
            },
            {
                label: `Search "${value}" in Others`,
                value: `/search/content-search/${value}?content-type=other`,
            },
        ],
        [value]
    );

    return (
        <AutoComplete
            dropdownMatchSelectWidth={400}
            value={value}
            options={!!value && options}
            ref={inputRef}
            onSelect={value => {
                navigate(value);
                setValue('');
            }}
        >
            <Input.Search
                size="medium"
                placeholder="Search"
                onChange={e => setValue(e.target.value)}
                allowClear
                onSearch={value => {
                    if (!value) return;
                    setValue('');
                    navigate(`/search/content-search/${value}`);
                    inputRef.current.blur();
                }}
            />
        </AutoComplete>
    );
}
export default AutoCompleteComponent;
