import React from 'react';
import { Button, Result, Space } from 'antd';
import { Link } from 'react-router-dom';

export default function SomethingWentWrong() {
    return (
        <Result
            status="500"
            title="Uh Oh!"
            subTitle="Sorry, something went wrong."
            extra={
                <Space>
                    <Link to="/">
                        <Button>Back Home</Button>
                    </Link>
                    <Link reloadDocument>
                        <Button type="primary">Reload</Button>
                    </Link>
                    <Link to=".." relative="path">
                        <Button type="primary">Go Back</Button>
                    </Link>
                </Space>
            }
        />
    );
}
