import React, { useState, useMemo, useRef } from 'react';
import { Card, Spin } from 'antd';
import { useThemeStore } from '../../store/theme';
import Config from '../../config/Config';
import LazyLoad from 'react-lazy-load';
import IframeNavigation from 'common/IframeNavigation';

export default function DashboardWidget({ url }) {
    const iframeRef = useRef(null);
    const theme = useThemeStore(state => state.theme);
    const dashboardURL = useMemo(
        () =>
            url?.includes('?')
                ? `${Config.GRAFANA}${url}&theme=${theme}`
                : `${Config.GRAFANA}${url}?theme=${theme}`,
        [theme, url]
    );

    const [loading, setLoading] = useState(true);

    return (
        <LazyLoad
            style={{ width: '100%', overflow: 'hidden' }}
            height={'100%'}
            threshold={0.6}
        >
            <Spin
                size="large"
                style={{
                    top: 200,
                    height: '100%',
                }}
                spinning={loading}
                wrapperClassName="health-check-spinner"
            >
                <div style={{ position: 'absolute', zIndex: 1 }}>
                    <IframeNavigation iframeRef={iframeRef} />
                </div>
                <Card
                    bodyStyle={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                    }}
                    hoverable
                    key={url}
                    style={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <iframe
                        ref={iframeRef}
                        key={url}
                        src={dashboardURL}
                        style={{
                            position: 'relative',
                            left: `-5.9rem`,
                            width: 'calc(100% + 8rem)',
                            height: '100%',
                        }}
                        onLoad={() => setLoading(false)}
                    />
                </Card>
            </Spin>
        </LazyLoad>
    );
}
