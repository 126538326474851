import { RightOutlined } from '@ant-design/icons';
import { Badge, Button, List, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNotificationsStore } from 'store/notifications';

export default function ViewAllNotifications() {
    const [notifications, setNotifications] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { getNotifications, markNotificationAsRead } = useNotificationsStore(
        state => ({
            getNotifications: state.getNotifications,
            markNotificationAsRead: state.markNotificationAsRead,
        })
    );

    const fetchNotifications = async (page, limit) => {
        setLoading(true);
        await getNotifications(false, page, limit).then(response =>
            setNotifications(response?.data)
        );
        setLoading(false);
    };

    useEffect(() => {
        (async () => await fetchNotifications())();
    }, []);

    return (
        <>
            <Button block type="link" onClick={() => setOpen(true)}>
                View All Notifications {<RightOutlined />}
            </Button>
            <Modal
                centered
                open={open}
                onCancel={() => setOpen(false)}
                bodyStyle={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
                footer={null}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={notifications?.data}
                    className="notification_list"
                    loading={loading}
                    pagination={{
                        style: { paddingTop: '1em', textAlign: 'center' },
                        position: 'bottom',
                        total: notifications?.meta?.total_records,
                        onChange: (page, pageSize) =>
                            fetchNotifications(page, pageSize),
                    }}
                    renderItem={notification => (
                        <List.Item style={{ marginBottom: 0 }}>
                            <List.Item.Meta
                                title={
                                    <Space size={'large'}>
                                        <Typography.Link
                                            onClick={async () => {
                                                setOpen(false);
                                                if (!!notification?.is_read)
                                                    return;
                                                await markNotificationAsRead(
                                                    notification?.notification_id
                                                );
                                                await fetchNotifications(
                                                    notifications?.meta?.page,
                                                    notifications?.meta?.limit
                                                );
                                            }}
                                        >
                                            {notification?.title}
                                        </Typography.Link>
                                        {!notification?.is_read && (
                                            <Badge color={'blue'} />
                                        )}
                                    </Space>
                                }
                                description={notification?.message}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
}
